@import '../../../../scss/theme-bootstrap';

.video-block {
  position: relative;
  &__close {
    text-align: right;
  }
  .icon--close {
    visibility: hidden;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    color: $black;
    margin: 0 3px 5px 5px;
    @include breakpoint($medium-up) {
      font-size: 20px;
      margin: 0 0 15px 0;
    }
  }
  &.active {
    .icon--close {
      visibility: visible;
    }
  }
  &__container {
    position: relative;
  }
  &__reveal {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    .video-block.active & {
      display: block;
      opacity: 1;
      height: 100%;
      iframe {
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
  video {
    display: block;
    width: 100%;
  }
  &__landing {
    position: relative;
    transition: opacity 0.3s;
    cursor: pointer;
    .video-block.active & {
      z-index: 0;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 40px;
  }
  &__image {
    display: block;
    width: 100%;
  }
  &__text {
    display: none;
    width: 100%;
    text-align: center;
    margin: 10px auto;
  }
}
